.projects {
  margin-top: 70px;
}

.projectTable {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.tableRow {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  color: #8c8c8c;
  font-size: 0.9rem;
  padding: 15px;
  border: 1px solid #252525;
  border-bottom: none;
}
.tableRow:nth-child(3) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.tableRow:last-child {
  border-bottom: 1px solid #252525;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.tableRow > .projectTitleHeader {
  min-width: 20%;
  display: flex;
}

.tableRow > .projectDescriptionHeader {
  min-width: 50%;
}
.tableRow > .projectStarsHeader {
  min-width: 10%;
  display: flex;
  align-items: center;
}

.tableRow > .projectStarsHeader svg {
  margin-right: 10px;
  width: 15px;
}

.tableRow > .projectStarsHeader svg path {
  fill: #888888;
}

.tableRow > .projectStarsHeader:hover svg path {
  fill: #ffffff;
}

.tableRow > .projectGithub {
  min-width: 10%;
}

.tableRow > .projectLive {
  min-width: 10%;
}

.tableRow svg {
  margin-left: 10px;
}

.tableRow a {
  text-decoration: none;
}

.tableRow a:hover path {
  fill: #272ab0;
}

.projectRowHeader {
  border: none;
}

.projectRowHeader > div {
  color: #b1b1b1;
  font-size: 0.8rem;
}

.projectTitle {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.projectTable a {
  text-decoration: none;
}
@media only screen and (max-width: 1300px) {
  .tableRow > .projectDescriptionHeader {
    display: none;
  }

  .tableRow > .projectTitleHeader {
    min-width: 60%;
  }

  .tableRow > .projectGithub {
    min-width: 20%;
  }

  .tableRow > .projectLive {
    min-width: 20%;
  }
}
@media only screen and (max-width: 700px) {
  .projectTable {
    width: 100%;
  }

  .tableRow > .projectTitleHeader {
    min-width: 70%;
  }

  .tableRow > .projectGithub {
    min-width: 30%;
  }

  .tableRow > .projectLive {
    display: none;
  }

  .projectLiveHeader {
    display: none;
  }

  .tableRow {
    border-right: none;
    border-left: none;
  }

  .tableRow:nth-child(3) {
    border-radius: 0;
  }

  .tableRow:last-child {
    border-radius: 0;
  }

  .projectTitle {
    margin: 10px;
  }
  .tableRow > .projectStarsHeader {
    display: none;
  }
}
