.headerWrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.aboutMe .name {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}
.aboutMe .description {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #b3b3b3;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.aboutMeWrapper {
  display: flex;
  align-items: center;
}
.aboutMeImg {
  width: 50px;
  border-radius: 100px;
  margin-right: 20px;
  align-self: flex-start;
}

.skills {
  margin-top: 15px;
}
.skills svg {
  width: 25px;
  height: auto;
  margin-right: 15px;
  margin-bottom: 10px;
}
.contactMe {
  display: flex;
  align-items: center;
}
.contactMe a:first-child {
  display: flex;
  align-items: center;
}

.contactMe a:first-child:hover svg path {
  opacity: 0.7;
}

.contactMe a:first-child svg path {
  fill: #ffffff;
}

.contactMe a svg {
  margin-left: 15px;
  width: 18px;
}
.contactMe a {
  background: #222222;
  font-size: 0.9rem;
  text-decoration: none;
  border-radius: 4px;
  padding: 10px 15px;
  letter-spacing: 0.3px;
  margin-left: 15px;
}

.contactMe a:hover {
  background: #272ab0;
}

.contactMe a:first-child {
  background: none;
}
@media only screen and (max-width: 1000px) {
  .headerWrapper {
    flex-direction: column;
  }

  .aboutMe {
    width: 100%;
  }

  .aboutMeImg {
    margin-bottom: 20px;
  }

  .contactMe {
    margin-top: 20px;
    width: 100%;
  }

  .contactMe a {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 650px) {
  .aboutMeWrapper {
    flex-direction: column;
    text-align: center;
  }

  .aboutMeImg {
    align-self: center;
    margin: 0 0 20px 0;
    width: 30%;
  }

  .skills svg {
    width: 20px;
  }

  .skills {
    display: flex;
    justify-content: space-between;
  }

  .aboutMe .description {
    line-height: 1.5;
  }

  .contactMe {
    flex-direction: column;
  }

  .contactMe a {
    width: 100%;
    padding: 15px;
    text-align: center;
  }

  .contactMe a:first-child {
    display: block;
  }
}
